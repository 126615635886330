import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import LastCTASection from "../components/lastCTASection"
import ListSection from "../components/listSection"
import SubservicesSlider from "../components/sliders/subservicesSlider"
import { useAnimatedBorder } from "../helpers"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import { Container, BaseButton, SideBox } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import { BigMobileFont } from "../components/typography"
import BaseImg from "../components/baseImg"
import CaseStudySection from "../components/caseStudySection"
import AboutSection from "../components/aboutSection"
import ArticlesGrid from "../components/articlesGrid"

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
  margin-bottom: 70px;
  @media (max-width: 1000px) {
    height: auto;
  }
`
const HeroFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`
const HeroImg = styled(BaseImg)`
  width: 33%;
  @media (max-width: 900px) {
    width: 75%;
  }
`

const SingleService = ({ pageContext }) => {
  const { blogPosts } = pageContext
  const {
    firstSectionButtonText,
    firstSectionButtonUrl,
    firstSectionText,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    listSectionTitle,
    subservices,
    list,
    firstSectionImage,
    caseStudyImage,
    caseStudyTitle,
    caseStudyText,
    partners,
    caseStudyButtonText,
    caseStudyButtonUrl,
    aboutSectionImage,
    aboutSectionTitle,
    aboutSectionText,
    blogSectionTitle,
  } = pageContext.metaboxes
  const [scale, boxRef] = useAnimatedBorder(2)
  const isCaseStudyDataCorrect =
    !!caseStudyImage &&
    !!caseStudyTitle &&
    !!caseStudyText &&
    !!partners &&
    !!caseStudyButtonText &&
    !!caseStudyButtonUrl
  const isAboutSectionDataCorrect =
    !!aboutSectionImage && !!aboutSectionTitle && !!aboutSectionText

  return (
    <Layout>
      <SEO
        meta={pageContext.yoast_meta}
        title={decodeHtmlCharCodes(pageContext.title)}
        path={pageContext.path}
      />
      <Container size="medium">
        <HeroSection>
          <HeroFlexWrapper>
            <HeroImg
              localFile={firstSectionImage ? firstSectionImage.localFile : null}
              alt=""
            />

            <SideBox scale={scale} ref={boxRef}>
              <h1 style={{ marginBottom: "0.75rem" }}>
                <BigMobileFont desktopFontSize="2.5rem">
                  {decodeHtmlCharCodes(pageContext.title)}
                </BigMobileFont>
              </h1>
              <p dangerouslySetInnerHTML={{ __html: firstSectionText }}></p>
            </SideBox>
          </HeroFlexWrapper>
          {firstSectionButtonText && (
            <BaseButton
              style={{ fontWeight: "600", padding: "1rem 4rem" }}
              to={firstSectionButtonUrl}
            >
              {firstSectionButtonText}
            </BaseButton>
          )}
        </HeroSection>
      </Container>
      <SubservicesSlider subservices={subservices} />

      {isCaseStudyDataCorrect ? (
        <CaseStudySection
          image={caseStudyImage}
          title={caseStudyTitle}
          text={caseStudyText}
          partners={partners}
          buttonText={caseStudyButtonText}
          buttonUrl={caseStudyButtonUrl}
        />
      ) : null}

      {isAboutSectionDataCorrect ? (
        <Container>
          <AboutSection
            image={aboutSectionImage.localFile}
            title={aboutSectionTitle}
            text={aboutSectionText}
          />
        </Container>
      ) : null}
      <ListSection title={listSectionTitle} items={list} position="center" />
      <div style={{ margin: "0 0 130px 0" }}>
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {blogSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ArticlesGrid posts={blogPosts.data.allWordpressPost.nodes} />
        </Container>
      </div>
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default SingleService
